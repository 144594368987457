<template>
    <div class="turntable">
        <Top/>
        <Menu/>
        <div class="turntable_content">
            <div class="turntable_content01">
                <div class="turntable_content011">
                    Surplus: {{ activityNum }}
                </div>
                <i class="el-icon-time" @click="openHistoryList()"></i>
            </div>

            <div class="turntable_content_div">
                <div class="turntable_content_lucky">
                    <LuckyWheel
                            ref="myLucky"
                            width="450px"
                            height="450px"
                            :prizes="prizes"
                            :blocks="blocks"
                            :buttons="buttons"
                            @end="endCallback">
                    </LuckyWheel>
                </div>

                <div class="turntable_content_start">
                    <div @click="startCallback"> GO </div>
                    <img src="../../assets/images/zhiz.png" @click="startCallback"/>
                </div>
            </div>

            <div class="turntable_content02">
                <div>Introduce: </div>
                <div>1、You can get one free spin per day.</div>
                <div>2、By completing tasks and inviting friends, you can obtain more</div>
                <div>spinning wheel times.</div>
            </div>

        </div>

        <el-dialog title="History" :visible.sync="dialogTableVisible">
            <el-table
                    :data="activityData"
                    height="300px"
                    width="245px"
                    v-loading="isLoading"
                    loading-text="Loading..."
                    loading-spinner="el-icon-loading"
            >

                <el-table-column property="history_data" label="Rewards" width="90"></el-table-column>
                <el-table-column property="history_time" label="Time" width="150"></el-table-column>

            </el-table>
        </el-dialog>

    </div>
</template>

<script>
    import Top from "../../components/framing/Top";
    import Menu from "../../components/framing/Menu";
    import request from "../../tools/request";

    export default {
        name: "Index",
        components: {Top, Menu},
        data () {
            return {
                userId: 0,
                isEnd: true,
                dialogTableVisible: false,
                isLoading: false,
                activityData: [],
                activityNum: 0,
                // 背景
                blocks: [{ padding: '13px', background: '#617df2' }],
                // 奖品
                prizes: [
                    {
                        range: 10,
                        background: '#e9e8fe',
                        fonts: [{
                            text: '100000 GC',
                            top: '5%',
                            fontColor: "#617df2",
                            fontSize: "15px"
                        }],
                        imgs: [{
                            src: "https://s2.loli.net/2024/08/20/YbkOtLDgPCQmHi2.webp",
                            top: "15%",
                            width: "80px",
                            height: "80px",
                        }]
                    },
                    {
                        range: 10,
                        background: '#b8c5f2',
                        fonts: [{
                            text: '50000 GC',
                            top: '5%',
                            fontColor: "#617df2",
                            fontSize: "15px"
                        }],
                        imgs: [{
                            src: "https://s2.loli.net/2024/08/20/YbkOtLDgPCQmHi2.webp",
                            top: "15%",
                            width: "80px",
                            height: "80px",
                        }]
                    },
                    {
                        range: 10,
                        background: '#e9e8fe',
                        fonts: [{
                            text: '10000 GC',
                            top: '5%',
                            fontColor: "#617df2",
                            fontSize: "15px"
                        }],
                        imgs: [{
                            src: "https://s2.loli.net/2024/08/20/YbkOtLDgPCQmHi2.webp",
                            top: "15%",
                            width: "80px",
                            height: "80px",
                        }]
                    },
                    {
                        range: 10,
                        background: '#b8c5f2',
                        fonts: [{
                            text: '1000 GC',
                            top: '5%',
                            fontColor: "#617df2",
                            fontSize: "15px"
                        }],
                        imgs: [{
                            src: "https://s2.loli.net/2024/08/20/YbkOtLDgPCQmHi2.webp",
                            top: "15%",
                            width: "80px",
                            height: "80px",
                        }]
                    },
                    {
                        range: 10,
                        background: '#e9e8fe',
                        fonts: [{
                            text: '2000 GC',
                            top: '5%',
                            fontColor: "#617df2",
                            fontSize: "15px"
                        }],
                        imgs: [{
                            src: "https://s2.loli.net/2024/08/20/YbkOtLDgPCQmHi2.webp",
                            top: "15%",
                            width: "80px",
                            height: "80px",
                        }]
                    },
                    {
                        range: 10,
                        background: '#b8c5f2',
                        fonts: [{
                            text: '5000 GC',
                            top: '5%',
                            fontColor: "#617df2",
                            fontSize: "15px"
                        }],
                        imgs: [{
                            src: "https://s2.loli.net/2024/08/20/YbkOtLDgPCQmHi2.webp",
                            top: "15%",
                            width: "80px",
                            height: "80px",
                        }]
                    },
                    {
                        range: 10,
                        background: '#e9e8fe',
                        fonts: [{
                            text: '100 GC',
                            top: '5%',
                            fontColor: "#617df2",
                            fontSize: "15px"
                        }],
                        imgs: [{
                            src: "https://s2.loli.net/2024/08/20/YbkOtLDgPCQmHi2.webp",
                            top: "15%",
                            width: "80px",
                            height: "80px",
                        }]
                    },
                    {
                        range: 10,
                        background: '#b8c5f2',
                        fonts: [{
                            text: '500 GC',
                            top: '5%',
                            fontColor: "#617df2",
                            fontSize: "15px"
                        }],
                        imgs: [{
                            src: "https://s2.loli.net/2024/08/20/YbkOtLDgPCQmHi2.webp",
                            top: "15%",
                            width: "80px",
                            height: "80px",
                        }]
                    },
                ],
                buttons: [
                    {
                        radius: '0px',
                    }
                ],
            }
        },
        watch: {},
        created() {
            if (sessionStorage.getItem("userId")) {
                this.userId = sessionStorage.getItem("userId");
            }
        },
        mounted() {
            if (sessionStorage.getItem("userId")) {
                this.userId = sessionStorage.getItem("userId");
            }

            this.getVerifyUserLogin();
            this.getLuckyTurntableNum();
        },
        destroyed() {
        },
        methods: {
            async getVerifyUserLogin() {
                if (sessionStorage.getItem("userId")) {
                    this.userId = sessionStorage.getItem("userId");

                    await request.getVerifyUserLogin(sessionStorage.getItem("userId")).then((res) => {
                        if (!res.data) {
                            this.$router.push("/login");
                        }
                    }).catch((err) => {
                        this.$message.error(err.message);
                    })
                } else {
                    this.$router.push("/login");
                }
            },
            async getLuckyTurntableNum() {
                if (this.userId) {
                    await request.getLuckyTurntableNum(this.userId).then((res) => {
                        this.activityNum = parseInt(res.data);
                    }).catch((err) => {
                        this.$message.error(err.message);
                    })
                }
            },
            // 点击抽奖按钮会触发star回调
            async startCallback () {
                if (this.activityNum === 0) {
                    this.$message.warning("No participation quota");
                    return;
                }
                if (sessionStorage.getItem("userId") && sessionStorage.getItem("userToken")) {
                    if (this.isEnd) {
                        this.isEnd = false;

                        // 调用抽奖组件的play方法开始游戏
                        await this.$refs.myLucky.play();

                        // 模拟调用接口异步抽奖
                        await request.getLuckyTurntableIndex(sessionStorage.getItem("userId")).then((res) => {
                            // console.log(res);
                            if (res.code && parseInt(res.code) === 1) {
                                const index = parseInt(res.data) - 1;
                                // 调用stop停止旋转并传递中奖索引
                                this.$refs.myLucky.stop(index);

                                this.getLuckyTurntableNum();
                            } else {
                                if (res.code && parseInt(res.code) === 401) {
                                    this.$message.warning("Invalid login information!");

                                    setTimeout(() => {
                                        this.$router.push("/login");
                                    }, 1000);
                                } else if (res.code && parseInt(res.code) === 403) {
                                    this.$message.warning("illegal request!");

                                    setTimeout(() => {
                                        this.$router.push("/login");
                                    }, 1000);
                                } else {
                                    this.$message.warning(res.message);
                                }
                            }
                        }).catch((err) => {
                            this.$message.error(err.message);
                        })
                    } else {
                        this.$message.warning("We are currently in a lottery draw. Please be patient and wait...");
                    }
                } else {
                    this.$message.warning("Login information has expired!");

                    setTimeout(() => {
                        this.$router.push("/login");
                    }, 1000);
                }
            },
            // 抽奖结束会触发end回调
            endCallback (prize) {
                this.isEnd = true;
                this.$message.success("Congratulations on receiving " + prize.fonts[0].text + " !")
            },
            async openHistoryList() {
                if (this.userId) {
                    this.isLoading = true;
                    this.dialogTableVisible = true;

                    await request.getIntegralHistorys(this.userId, 4001, 0, null).then((res) => {
                        this.activityData = res.data;
                        this.isLoading = false;
                    }).catch((err) => {
                        this.$message.error(err.message);
                    })
                }
            }
        }
    }
</script>

<style lang="less">
    .turntable {
        background-color: #131313;
        position: absolute;
        width: 100%;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        .turntable_content {
            position: absolute;
            top: 100px;
            bottom: 100px;
            width: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            flex-wrap: nowrap;
            .turntable_content_div {
                width: 450px;
                height: 450px;
                position: relative;
                .turntable_content_lucky {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                .turntable_content_start {
                    position: absolute;
                    top: 130px;
                    left: 175px;
                    div {
                        position: relative;
                        top: 83px;
                        left: 29px;
                        font-size: 24px;
                        font-weight: 600;
                        color: white;
                        cursor: pointer;
                    }
                    img {
                        width: 100px;
                        cursor: pointer;
                    }
                }
            }

            .turntable_content01 {
                color: white;
                font-size: 35px;
                width: 100%;
                margin-right: 50px;

                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                .turntable_content011 {
                    font-size: 16px;
                    margin-left: 50px;
                }
                i {
                    cursor: pointer;
                }
            }
            .turntable_content02 {
                width: 100%;
                color: white;
                text-align: left;
                margin-left: 50px;
                font-size: 14px;
            }
        }
    }

    .el-table__body-wrapper::-webkit-scrollbar {
        width: 0;
    }
    .el-table__body-wrapper {
        overflow: auto !important;
    }
    .el-table, .el-table__expanded-cell {
        background-color: white !important;
    }
</style>
